import React from 'react';
import {Table, Spin, Button, Divider} from 'antd';
import PropTypes from 'prop-types';
import {legalEntityFields} from '../../constants/legalEntity';
import {goToLegalEntity, goToDashboard} from '../../utils/gotoLink';
import {legalEntityStatus} from '../../constants/classificators';
import {
  translateLegalEntityStatusClassificator
} from '../../utils/translateHelpers';

class MarketParticipantsSearchResults extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    searchLegalEntities: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    searchResults: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loginByCompanyAsAdmin: PropTypes.func,
    filtersState: PropTypes.object,
    pageSize: PropTypes.number.isRequired,
  };

  getColumns = t => [
    {
      title: t('company_name'),
      dataIndex: legalEntityFields.NAME,
    },
    {
      title: t('registry_code'),
      dataIndex: legalEntityFields.REGISTER_CODE,
    },
    {
      title: t('company_type'),

      render: (value, values) => {
        const roles = [];

        // GREEN-212
        // it seems that translation classificators do not work correctly
        // added new translation into dashboard.js
        // remove or use another approach to get translation for the roles when it becomes possible

        // status translations are not rendered correctly (right now in Estonian when English is selected)

        if (values[legalEntityFields.TRADER]) roles.push(t('trader'));
        if (values[legalEntityFields.MANUFACTURER]) roles.push(t('manufacturer'));
        if (values[legalEntityFields.PROVIDER]) roles.push(t('provider'));
        if (values[legalEntityFields.LOADING_OPERATOR]) roles.push(t('loading_operator'));

        return roles.join(', ');
      }

      // GREEN-212
      // right now we believe it does not work the right way
      // commented out until we know how it supposed to work

      // render: (value, values) =>
      //   values[legalEntityFields.ACCOUNTS]
      //     ? uniq(
      //         values[legalEntityFields.ACCOUNTS]
      //           .filter(
      //             account =>
      //               account[accountFields.STATUS] === accountStatus.ACTIVE
      //           )
      //           .map(account =>
      //             translateAccountTypeClassificator(account[accountFields.TYPE])
      //           )
      //       ).join(', ')
      //     : '',
    },
    {
      title: t('status'),
      dataIndex: legalEntityFields.STATUS,
      render: translateLegalEntityStatusClassificator,
    },
    {
      title: t('options'),
      dataIndex: 'id',
      render: (text, record) => (
        <span>
          <Button
            onClick={() =>
              goToLegalEntity(record[legalEntityFields.REGISTER_CODE])
            }
            shape="circle"
            icon="edit"
            size="small"
            type="primary"
            title={t('editBtn')}
          />
          <Divider type="vertical"/>
          {record[legalEntityFields.STATUS] === legalEntityStatus.ACTIVE && (
            <Button
              shape="circle"
              icon="calendar"
              size="small"
              type="primary"
              title={t('loginAsBtn')}
              onClick={() => this.handleRoleChange(record.id)}
            />
          )}
        </span>
      ),
    },
  ];

  handleRoleChange = async id => {
    await this.props.loginByCompanyAsAdmin(id);
    goToDashboard();
  };

  handlePagination = value => {
    const {searchLegalEntities} = this.props;

    return searchLegalEntities(value);
  };

  render() {
    const {
      t,
      searchResults,
      totalElements,
      pageSize,
      page,
      loading,
    } = this.props;
    return (
      <Spin spinning={loading}>
        <div className="search-results-container">
          <Table
            pagination={{
              onChange: this.handlePagination,
              total: totalElements,
              pageSize,
              current: page,
              defaultCurrent: 1,
            }}
            rowKey={record => record.id}
            columns={this.getColumns(t)}
            dataSource={searchResults}
          />
        </div>
      </Spin>
    );
  }
}

export default MarketParticipantsSearchResults;
