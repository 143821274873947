import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'antd';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {isString} from 'lodash';
import {productionUnitFields} from '../../constants/productionUnit';
import {
  reduxForm,
  FieldArray,
  formValueSelector,
  SubmissionError,
} from 'redux-form/immutable';
import {message} from 'antd';
import {tAddEquipment} from '../../i18n';
import {
  FORM_FUELS_USED,
  fuelsUsedFormFields,
} from '../../constants/formsAddEquipment';
import {
  updateFuelsWithDelete,
  requestProductionUnitUpdate,
  fetchFeedstockAndProductionPathwayClassifiers,
} from '../../actions/actionCreators/productionUinitActionCreators';
import {RegisterActions} from '../../components';
import FuelUnit from './FuelUnit';
import addInitialFuels from './addInitialFuels';
import './AddEquipmentFuelsUsed.css';
import {getLocale} from '../../reducers/settingsReducer';
import FuelsUsedHistory from "./FuelsUsedHistory";
import {Link} from "react-router-dom";
import {getMeteringPointAgrDataEntities} from "../../reducers/meteringPointReducer";
import {fetchMeteringPointAgrData} from "../../actions/actionCreators/meteringPointActionCreators";
import {meteringPointAmountsConfirm} from "../../actions/actionHelpers/endpoints";
import moment from "moment";
import {getLoadingSelector} from "../../utils/asyncHelpers";
import {getLoadingUnitFuels} from "../../reducers/loadingUnitFuelsReducer";

export class AddEquipmentFuelsUsed extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    productionUnit: PropTypesImmutable.map.isRequired,
    totalPercentage: PropTypes.number.isRequired,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    showRequestChangeModal: PropTypes.func,
    fetchMeteringPointAgrData: PropTypes.func.isRequired,
  };

  getStatus = () => {
    const {fuels} = this.props;

    if (!!fuels && fuels.size > 0) {
      const fuelStatus = fuels.get(0).get('status');
      if (!fuelStatus) {
        return 'OPEN';
      }
      return fuels.get(0).get('status');
    }
    return null;
  };

  confirmMeteringPointAmounts = async () => {
    const {fetchMeteringPointAgrData, productionUnit} = this.props;

    const meteringPointId = productionUnit.get(productionUnitFields.METERING_POINT_ID);

    const month = moment().subtract(1, 'M').month() + 1;
    const year = moment().subtract(1, 'M').year();

    await meteringPointAmountsConfirm({meteringPointId, year: year, month: month});

    fetchMeteringPointAgrData({meteringPointId, year: year, month: month});
  };

  componentDidMount() {
    const {fetchMeteringPointAgrData, productionUnit} = this.props;

    const meteringPointId = productionUnit.get(productionUnitFields.METERING_POINT_ID);

    const month = moment().subtract(1, 'M').month() + 1;
    const year = moment().subtract(1, 'M').year();

    fetchMeteringPointAgrData({meteringPointId, year: year, month: month});
  }

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      productionUnit,
      fetchClassifiers,
      isLoading,
      locale,
      totalPercentage,
      inEditForNotAdmin,
      isEditMode,
      isAdmin,
      showRequestChangeModal,
      meteringData,
      submitting
    } = this.props;

    const isOpen = this.getStatus() === 'OPEN';

    const meteringPointId = productionUnit.get(productionUnitFields.METERING_POINT_ID);
    const measurementPointType = productionUnit.get(productionUnitFields.METERING_POINT_TYPE);
    const monthlyFuelDataConfirmed = productionUnit.get(productionUnitFields.MONTHLY_FUEL_DATA_CONFIRMED);
    const monthlyGoIssued = productionUnit.get(productionUnitFields.MONTHLY_GO_ISSUED);

    const meteringPointAmounts = meteringData.get(0);

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col span={12}>
            <h2>{t('fuelsUsed.title')}</h2>
            <div className={`status_${this.getStatus()}`}>
              {t('status')}: {t(this.getStatus())}
            </div>
          </Col>
          <Col span={12}>
            <FuelsUsedHistory t={t} productionUnitId={productionUnit.get(productionUnitFields.ID)}/>
          </Col>
        </Row>
        <br/>
        <br/>
        <FieldArray
          name={fuelsUsedFormFields.TYPES}
          component={FuelUnit}
          unitId={productionUnit.get(productionUnitFields.ID)}
          t={t}
          locale={locale}
          isLoading={isLoading}
          fetchClassifiers={fetchClassifiers}
          inEditForNotAdmin={inEditForNotAdmin}
          totalPercentage={totalPercentage}
          isAdmin={isAdmin}
          monthlyFuelDataConfirmed={monthlyFuelDataConfirmed}
          monthlyGoIssued={monthlyGoIssued}
        />

        <div>
          {meteringPointId && measurementPointType === 'PRODUCTION' && (
            <Link
              className="production-point__goto-unit pull-left"
              href
              to={`/meteringPoint/${meteringPointId}/data`}
            >
              {t('meteringPointDataStatisticsLink')}
            </Link>
          )}
          <RegisterActions
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            isOpen={isOpen}
            showRequestChangeModal={showRequestChangeModal}
            goPrevious={goPrevious}
            showSaveButton={isAdmin || !isEditMode}
            saveButtonDisabled={(monthlyFuelDataConfirmed && monthlyGoIssued) || isLoading}
            t={t}
          />
          {!isAdmin && isOpen && (
            <div className="component-register-actions">
              <Button size="large" type="primary" htmlType="submit" disabled={submitting}>
                {t('confirmFuelUpdateBtn')}
              </Button>
            </div>
          )}
        </div>

        {meteringPointAmounts && measurementPointType === 'PRODUCTION' && (
          <div>
            <Row>
              <Col span={3}>
                <div>
                  {t('meteringPointAmounts')}
                </div>
                <div>
                  <b>{meteringPointAmounts.get('producedAmount')} MWh, {meteringPointAmounts.get('producedVolume')} m<sup>3</sup></b>
                </div>
              </Col>
              <Col span={9}>
                <Button size="large" type="primary" onClick={this.confirmMeteringPointAmounts}
                        disabled={meteringPointAmounts.get('confirmed')}>
                  {t('meteringPointAmountsConfirm')}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </form>
    );
  }
}

const valueSelector = formValueSelector(FORM_FUELS_USED);

const getIsLoading = getLoadingSelector(getLoadingUnitFuels);

const mapStateToProps = (state, ownProps) => {

  const fuels = valueSelector(state, fuelsUsedFormFields.TYPES);

  const totalPercentage = fuels
    ? fuels
      .filter(f => f.get(fuelsUsedFormFields.IS_FUEL_USED))
      .reduce(
        (sum, x) =>
          sum + parseInt(x.get(fuelsUsedFormFields.PERCENTAGE, 0), 10),
        0
      )
    : 0;

  return {
    initialValues: addInitialFuels(ownProps.productionUnit),
    locale: getLocale(state),
    totalPercentage,
    fuels,
    meteringData: getMeteringPointAgrDataEntities(state),
    isLoading: getIsLoading(state)
  };
};

const mapDispatchToProps = {
  fetchClassifiers: fetchFeedstockAndProductionPathwayClassifiers,
  fetchMeteringPointAgrData: fetchMeteringPointAgrData
};

const onSubmitFail = errors => {
  if (isString(errors)) {
    message.error(errors);
  }
};

const onSubmit = (values, dispatch, ownProps) => {
  let isOpen = false;

  if (ownProps.totalPercentage !== 100) {
    throw new SubmissionError(tAddEquipment('fuelsUsed.percentageError'));
  }

  if (
    values.get('productionUnitFuels').get(0) !== undefined &&
    (values
        .get('productionUnitFuels')
        .get(0)
        .get('status') === undefined ||
      values
        .get('productionUnitFuels')
        .get(0)
        .get('status') === 'OPEN')
  ) {
    isOpen = true;
  }

  const filtered = values.set(
    fuelsUsedFormFields.TYPES,
    values
      .get(fuelsUsedFormFields.TYPES)
      .filter(f => f.get(fuelsUsedFormFields.IS_FUEL_USED))
  );

  if (!isOpen) {
    return dispatch(updateFuelsWithDelete(filtered.toJS()));
  } else {
    return dispatch(requestProductionUnitUpdate(filtered.toJS()));
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_FUELS_USED,
    onSubmitFail,
    enableReinitialize: true,
    onSubmit
  })(AddEquipmentFuelsUsed)
);
