import {createValidation, validatorRequired} from '../../utils/formValidators';
import {formRegisterGeneralFields} from '../../constants/formRegister';
import {tCommon as t} from '../../i18n';

const accountSelectValidator = message => (value, form) => {
  if (!message) {
    message = t('formAccountShouldSelect');
  }
  if (
    form.get(formRegisterGeneralFields.IS_TRADER) ||
    form.get(formRegisterGeneralFields.IS_MANUFACTURER) ||
    form.get(formRegisterGeneralFields.IS_PROVIDER) ||
    form.get(formRegisterGeneralFields.IS_LOADING_OPERATOR)
  ) {
    return undefined;
  }

  return message;
};

export default createValidation([
  {
    name: formRegisterGeneralFields.ROLE,
    validators: validatorRequired(),
  },
  {
    name: formRegisterGeneralFields.COMPANY_NAME,
    validators: validatorRequired(),
  },
  {
    name: formRegisterGeneralFields.IS_TRADER,
    validators: accountSelectValidator(),
  },
  {
    name: formRegisterGeneralFields.IS_MANUFACTURER,
    validators: accountSelectValidator(),
  },
  {
    name: formRegisterGeneralFields.IS_LOADING_OPERATOR,
    validators: accountSelectValidator(),
  },
]);
