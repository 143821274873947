import React from 'react';
import {connect} from 'react-redux';
import {Spin, Tabs} from 'antd';
import {
  RegisterManagement,
  RegisterContacts,
  RegisterProductionUnits,
  RequestChangeModal,
  RegisterLoadingUnits
} from '../';
import {fetchLegalEntityActionCreator} from '../../actions/actionCreators/registerActionCreators';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {getLegalEntity} from '../../schemas';
import {RegisterGeneral, RegisterAdditional, RegisterBank} from '../../forms';
import {formRegisterGeneralFields} from '../../constants/formRegister';
import {legalEntitySelector} from '../../reducers/registerReducer';
import {legalEntityFields} from '../../constants/legalEntity';
import {getRole} from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';
import {showRequestChangeLegalEntityModal} from '../../actions/actionCreators/modalsActionCreators';
import * as generalActions from '../../actions/actionCreators/registerGeneralActionCreators';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {notificationRelatedObjectType} from '../../constants/classificators';

const {TabPane} = Tabs;

const getNumberSteps = (isManufacturer, isOperator) => {
  if (isManufacturer) {
    return 6
  }

  if (isOperator) {
    return 4
  }

  return 3;
};


class LegalEntityEdit extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchLegalEntity: PropTypes.func.isRequired,
    registerCode: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    legalEntity: PropTypes.object,
    role: PropTypes.string.isRequired,
    showRequestChange: PropTypes.func.isRequired,
    queryParams: PropTypes.object
  };

  state = {
    activeTab: '',
  };

  componentDidMount = () => {
    const {fetchLegalEntity, registerCode, queryParams} = this.props;

    fetchLegalEntity({registerCode});

    const key = queryParams && queryParams.tab ? queryParams.tab : '1';
    this.handleTabChange(key);
  };

  handleTabChange = key => {
    this.setState({
      activeTab: key,
    });
  };

  render() {
    const {
      t,
      isLoading,
      legalEntity,
      role,
      showRequestChange,
      registerCode,
      queryParams,
    } = this.props;
    const isTrader =
      legalEntity &&
      !legalEntity.get(formRegisterGeneralFields.IS_MANUFACTURER);
    const isLoadingOperator =
      legalEntity &&
      !!legalEntity.get(formRegisterGeneralFields.IS_LOADING_OPERATOR);
    const isManufacturer =
      legalEntity &&
      !!legalEntity.get(formRegisterGeneralFields.IS_MANUFACTURER);
    const isEditMode = true;
    const isUserAdmin = isAdmin(role);

    const numberOfSteps = getNumberSteps(isManufacturer, isLoadingOperator);

    return (
      <Spin spinning={isLoading}>
        <div>
          <h1>{t('titleEdit', {name: legalEntity.get('name')})}</h1>
          <Tabs type="card" onChange={this.handleTabChange} activeKey={this.state.activeTab}>
            <TabPane tab={t('title_general')} key="1">
              <RegisterGeneral
                isEditMode={isEditMode}
                t={t}
                legalEntity={legalEntity}
                isAdmin={isUserAdmin}
                showRequestChangeModal={showRequestChange}
                registerCode={registerCode}
              />
            </TabPane>
            {isLoadingOperator && !isManufacturer && (
              <TabPane tab={t('title_additional')} key="2">
                <RegisterAdditional
                  isEditMode={isEditMode}
                  t={t}
                  legalEntity={legalEntity}
                  isAdmin={isUserAdmin}
                  showRequestChangeModal={showRequestChange}
                  numberOfSteps={numberOfSteps}
                />
              </TabPane>
            )}
            <TabPane tab={t('title_contact')} key="3">
              <RegisterContacts
                isEditMode={isEditMode}
                t={t}
                legalEntity={legalEntity}
                isAdmin={isUserAdmin}
                showRequestChangeModal={showRequestChange}
              />
            </TabPane>
            {!isTrader && (
              <TabPane tab={t('title_additional')} key="4">
                <RegisterAdditional
                  isEditMode={isEditMode}
                  t={t}
                  legalEntity={legalEntity}
                  isAdmin={isUserAdmin}
                  showRequestChangeModal={showRequestChange}
                  numberOfSteps={numberOfSteps}
                />
              </TabPane>
            )}
            {!isTrader && (
              <TabPane tab={t('title_production')} key="5">
                {this.state.activeTab === '5' && (
                  <RegisterProductionUnits
                    isEditMode={isEditMode}
                    t={t}
                    legalEntity={legalEntity}
                    isAdmin={isUserAdmin}
                    showRequestChangeModal={showRequestChange}
                    queryParams={queryParams}
                  />
                )}
              </TabPane>
            )}
            {isLoadingOperator && (
              <TabPane tab={t('title_loading_unit')} key="6">
                {this.state.activeTab === '6' && (
                  <RegisterLoadingUnits
                    isEditMode={isEditMode}
                    t={t}
                    legalEntity={legalEntity}
                    isAdmin={isUserAdmin}
                    showRequestChangeModal={showRequestChange}
                  />
                )}
              </TabPane>
            )}
            {!isTrader && (
              <TabPane tab={t('title_bank')} key="7">
                <RegisterBank
                  isEditMode={isEditMode}
                  t={t}
                  legalEntity={legalEntity}
                  isAdmin={isUserAdmin}
                  showRequestChangeModal={showRequestChange}
                />
              </TabPane>
            )}
            <TabPane tab={t('title_administration')} key="8">
              <RegisterManagement
                isEditMode={isEditMode}
                t={t}
                legalEntity={legalEntity}
                isAdmin={isUserAdmin}
                showRequestChangeModal={showRequestChange}
              />
            </TabPane>
          </Tabs>
        </div>
        <RequestChangeModal
          relatedObjectId={legalEntity.get(legalEntityFields.ID)}
          relatedObjectType={notificationRelatedObjectType.LEGAL_ENTITY}
        />
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: getLoadingSelector(legalEntitySelector)(state),
  legalEntity: getLegalEntity(state, legalEntitySelector(state)),
  role: getRole(state),
});

const mapDispatchToProps = {
  fetchLegalEntity: fetchLegalEntityActionCreator,
  fetchRegisterMandate: generalActions.fetchRegisterMandate,
  showRequestChange: showRequestChangeLegalEntityModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.register)(LegalEntityEdit)
);
