import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {Field, reduxForm, formValueSelector} from 'redux-form/immutable';
import {TextField, RadioField, DateField, NumberField} from '../../atoms';
import {connect} from 'react-redux';
import {Row, Col, Button} from 'antd';
import {FORM_GENERAL, generalFormFields} from '../../constants/formsAddEquipment';
import {RegisterActions, AutoCompleteCompanies} from '../../components';
import {
  updateProductionUnit,
  addProductionUnit,
  activateProductionUnit,
  deactivateProductionUnit,
} from '../../actions/actionCreators/productionUinitActionCreators';
import {productionUnitStatus} from '../../constants/classificators';
import {
  selectOwner as selectorOwnerAction
} from '../../actions/actionCreators/productionUnitAddActionCreators';
import {productionUnitFields} from '../../constants/productionUnit';
import validate from './validateSchema';

export class AddEquipmentGeneral extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    isInvestmentSupport: PropTypes.bool,
    productionUnit: PropTypesImmutable.map,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    selectProductionUnitOwner: PropTypes.bool.isRequired,
    legalEntityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showRequestChangeModal: PropTypes.func,
    selectOwner: PropTypes.func.isRequired,
    activate: PropTypes.func.isRequired,
    deactivate: PropTypes.func.isRequired,
  };

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      isInvestmentSupport,
      inEditForNotAdmin,
      isEditMode,
      isAdmin,
      selectProductionUnitOwner,
      legalEntityId,
      showRequestChangeModal,
      selectOwner,
      activate,
      deactivate,
      initialValues,
    } = this.props;

    const unitStatus = initialValues.get(productionUnitFields.STATUS);
    const unitId = initialValues.get(productionUnitFields.ID);

    return (
      <div>
        {selectProductionUnitOwner && (
          <AutoCompleteCompanies onChange={selectOwner} value={legalEntityId}/>
        )}
        <form onSubmit={handleSubmit}>
          <h2>{isEditMode ? t('general.titleEdit') : t('general.title')}</h2>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                label={t('general.name')}
                tooltip={t('general.nameTooltip')}
                required
                name={generalFormFields.NAME}
                component={TextField}
                disabled={inEditForNotAdmin || !legalEntityId}
              />
            </Col>
            {(isEditMode || isAdmin) && (
              <Col span={12}>
                <Field
                  name={generalFormFields.EICW_CODE}
                  component={TextField}
                  required={isAdmin}
                  type="text"
                  label={t('general.eicwCode')}
                  disabled={inEditForNotAdmin || !legalEntityId}
                />
              </Col>
            )}
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={generalFormFields.ADDRESS}
                component={TextField}
                required
                type="text"
                label={t('general.address')}
                disabled={inEditForNotAdmin || !legalEntityId}
              />
            </Col>
            <Col span={12}>
              <Field
                className="date-field"
                name={generalFormFields.FIRST_TIME_USE}
                component={DateField}
                required
                label={t('general.firstTimeUse')}
                tooltip={t('general.firstTimeUseTooltip')}
                disabled={inEditForNotAdmin || !legalEntityId}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Field
                name={generalFormFields.IS_INVESTMENT_SUPPORT}
                component={RadioField}
                required
                options={[
                  {
                    value: true,
                    label: t('general.isInvestmentSupport.trueLabel'),
                  },
                  {
                    value: false,
                    label: t('general.isInvestmentSupport.falseLabel'),
                  },
                ]}
                type="text"
                label={t('general.isInvestmentSupport.label')}
                disabled={inEditForNotAdmin || !legalEntityId}
              />
            </Col>
            <Col span={12}>
              {isInvestmentSupport && (
                <Field
                  name={generalFormFields.AMOUNT_OF_INVESTMENT}
                  component={NumberField}
                  required
                  type="text"
                  label={t('general.amountOfInvestment')}
                  disabled={inEditForNotAdmin || !legalEntityId}
                />
              )}
            </Col>
          </Row>
          <RegisterActions
            showRequestChangeModal={showRequestChangeModal}
            hasPrevious={hasPrevious}
            hasDone={hasDone}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            goPrevious={goPrevious}
            showSaveButton={isAdmin || !isEditMode}
            inEditForNotAdmin={inEditForNotAdmin}
            t={t}
          >
            {unitId &&
              isEditMode &&
              isAdmin && (
                <Button
                  size="large"
                  type="primary"
                  onClick={
                    unitStatus === productionUnitStatus.ACTIVE
                      ? () => deactivate(unitId)
                      : () => activate(unitId)
                  }
                >
                  {unitStatus === productionUnitStatus.ACTIVE
                    ? t('general.deactivateBtn')
                    : t('general.activateBtn')}
                </Button>
              )}
          </RegisterActions>
        </form>
      </div>
    );
  }
}

const onSubmit = async (values, dispatch, props) => {
  await dispatch(
    values.get(generalFormFields.ID)
      ? updateProductionUnit(values.toJS())
      : addProductionUnit(
        values
          .set(generalFormFields.LEGAL_ENTITY_ID, props.legalEntityId)
          .toJS()
      )
  );
};

const selector = formValueSelector(FORM_GENERAL);

const mapStateToProps = (state, ownProps) => ({
  isInvestmentSupport: selector(state, generalFormFields.IS_INVESTMENT_SUPPORT),
  initialValues: ownProps.productionUnit,
});

const mapDispatchToProps = {
  selectOwner: selectorOwnerAction,
  activate: activateProductionUnit,
  deactivate: deactivateProductionUnit,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_GENERAL,
    validate,
    enableReinitialize: true,
    onSubmit,
  })(AddEquipmentGeneral)
);
