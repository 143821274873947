import React from 'react';
import {ProductionUnitAdd} from '../../containers';
import {Layout} from 'antd';

class LegalEntityEdit extends React.Component {
  render() {
    return (
      <Layout className="main-container">
        <Layout.Content>
          <ProductionUnitAdd/>
        </Layout.Content>
      </Layout>
    );
  }
}

export default LegalEntityEdit;
