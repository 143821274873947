import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {getRole} from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';
import {goToProductionUnitSearch} from '../../utils/gotoLink';
import {AddEquipment} from '../';
import {getOwner} from '../../reducers/productionUnitAddReducer';
import {resetMasterActionCreator} from '../../actions/actionCreators/addEquipmentActionCreators';

export class ProductionUnitAdd extends Component {
  static propTypes = {
    legalEntityId: PropTypes.number,
    role: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    resetMasterActionCreator: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.props.resetMasterActionCreator();
  };

  render() {
    const {t, legalEntityId, role} = this.props;
    return (
      <div>
        <h2 className="register-management-container__subtitle">
          {t('productionUnit.title')}
        </h2>
        <AddEquipment
          legalEntityId={legalEntityId}
          isProductionUnitAdd={true}
          selectProductionUnitOwner={true}
          isAdmin={isAdmin(role)}
          isEditMode={false}
          onSubmitSuccess={goToProductionUnitSearch}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  legalEntityId: getOwner(state),
  role: getRole(state),
});

const mapDispatchToProps = {
  resetMasterActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.register)(ProductionUnitAdd)
);
