import {legalEntityFields} from './legalEntity';
import {meteringPointFields} from './meteringPoint';

export const FORM_REGISTER_GENERAL = 'FORM_REGISTER_GENERAL';
export const FORM_REGISTER_CONTACT = 'FORM_REGISTER_CONTACT';
export const FORM_REGISTER_ENTITY_CONTACT = 'FORM_REGISTER_ENTITY_CONTACT';
export const FORM_REGISTER_ADDITIONAL = 'FORM_REGISTER_ADDITIONAL';
export const FORM_REGISTER_REPRESENTATIVE_PERSON =
  'FORM_REGISTER_REPRESENTATIVE_PERSON';
export const FORM_REGISTER_REPRESENTATIVE_COMPANY =
  'FORM_REGISTER_REPRESENTATIVE_COMPANY';
export const FORM_REGISTER_BANK = 'FORM_REGISTER_BANK';
export const FORM_MEASUREMENT = 'FORM_MEASUREMENT';
export const FORM_GOS_CERTIFICATES_LOCK = 'FORM_GOS_CERTIFICATES_LOCK';

export const formRegisterGeneralFields = {
  ID: legalEntityFields.ID,
  ROLE: legalEntityFields.TYPE,
  COMPANY_NAME: legalEntityFields.NAME,
  REGISTER_CODE: legalEntityFields.REGISTER_CODE,
  KMKR_CODE: legalEntityFields.KMKR_CODE,
  EICX_CODE_B: legalEntityFields.EICX_CODE_B,
  EICX_CODE_E: legalEntityFields.EICX_CODE_E,
  STATUS: legalEntityFields.STATUS,
  IS_TRADER: 'trader',
  IS_MANUFACTURER: 'manufacturer',
  IS_PROVIDER: 'provider',
  IS_LOADING_OPERATOR: legalEntityFields.LOADING_OPERATOR,
  ELECTRIC_GOS_AUTO_ISSUE: legalEntityFields.ELECTRIC_GOS_AUTO_ISSUE
};

export const fromRegisterEntityContactFields = {
  COUNTRY: legalEntityFields.COUNTRY,
  CITY: legalEntityFields.CITY,
  ADDRESS: legalEntityFields.ADDRESS,
  ZIP_CODE: legalEntityFields.ZIP_CODE,
};

export const formRegisterContactFields = {
  ID: 'id',
  CONTACT_PERSON: 'contactPerson',
  PHONE: 'phoneNumber',
  PHONE_CODE: 'phoneCountryCode',
  EMAIL: 'contactEmail',
};

export const formRegisterAdditionalFields = {
  EMTAK_CODE: 'emtakCode',
  EHAK_CODE: 'ehakCode',
  WORKERS_NUMBER: 'employeeNumber',
};

export const formRegisterRepresentativePersonFields = {
  FIRST_NAME: 'name',
  LAST_NAME: 'lastName',
  PERSONAL_CODE: 'personalCode',
  MANDATE_DATE_START: 'startDate',
  MANDATE_DATE_END: 'endDate',
  MANDATE_ID: 'mandateId',
  ORIGINATOR_LEGAL_ENTITY_ID: 'originatorLegalEntityId'
};

export const formRegisterEntityRoleFields = {
  ROLE: 'role',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
};

export const formRegisterRepresentativeCompanyFields = {
  COMPANY_NAME: 'name',
  REGISTRY_CODE: 'registryCode',
  REGISTRATION_CODE: 'registrationCode',
  MANDATE_DATE_START: 'startDate',
  MANDATE_DATE_END: 'endDate',
  MANDATE_ID: 'mandateId',
  LEGAL_ENTITY_ID: 'legalEntityId',
  ORIGINATOR_LEGAL_ENTITY_ID: 'originatorLegalEntityId'
};

export const formRegisterBankFields = {
  RECEIVER_NAME: 'receiverName',
  RECEIVER_KMKR: 'receiverKMKRcode',
  RECEIVER_REGISTRY_CODE: 'receiverRegisterCode',
  BANK_NAME: 'bank',
  REFERENCE_NUMBER: 'referenceNumber',
  BANK_ACCOUNT: 'bankAccountNumber',
};

export const formRegisterMeasurementFields = {
  ID: 'id',
  PRODUCTION_UNIT_ID: 'productionUnitId',
  COUNTY: meteringPointFields.COUNTY,
  CITY: meteringPointFields.CITY,
  DISTRICT: meteringPointFields.DISTRICT,
  ADDRESS: meteringPointFields.ADDRESS,
  ZIP_CODE: meteringPointFields.ZIP_CODE,
  EICZ_CODE: meteringPointFields.EICZ_CODE,
};

export const formRegisterDocumentsFields = {
  ID: 'id',
  FILE: 'file',
  LEGAL_ENTITY_ID: 'legalEntityId',
  NAME: 'name',
};
