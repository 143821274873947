export const legalEntityFields = {
  ID: 'id',
  ADDRESS: 'address',
  TYPE: 'legalEntityType',
  TRADER: 'trader',
  MANUFACTURER: 'manufacturer',
  PROVIDER: 'provider',
  LOADING_OPERATOR: 'loadingOperator',
  NAME: 'name',
  REGISTER_CODE: 'registrationCode',
  KMKR_CODE: 'kmkrCode',
  EICX_CODE_B: 'eicxCode',
  EICX_CODE_E: 'eicxElectricityCode',
  STATUS: 'status',
  ACCOUNTS: 'accounts',
  COUNTRY: 'country',
  CITY: 'city',
  ZIP_CODE: 'zip',
  BUSINESS_FORM: 'businessForm',
  ELECTRIC_GOS_AUTO_ISSUE: 'electricGosAutoIssue'
};

export const businessRegisterFields = [
  legalEntityFields.ADDRESS,
  legalEntityFields.CITY,
  legalEntityFields.COUNTRY,
  legalEntityFields.ZIP_CODE,
  legalEntityFields.NAME,
  legalEntityFields.EICX_CODE,
  legalEntityFields.BUSINESS_FORM
];

export const REGISTER_CODE_LENGTH = 8;
