import {
  createAsyncAction,
  normalizeHelper,
  toSuccess,
} from '../../utils/asyncHelpers';
import productionUnitSchema from '../../schemas/productionUnit';
import {createAsyncPagingAction} from '../../utils/asyncPagingHelpers';
import {
  legalEntityProductionUnitFetch,
  productionUnitAdd,
  productionUnitUpdate,
  productionUnitFuelUpdateRequest,
  productionUnitDelete,
  productionUnitFetch,
  deleteUnitFuel,
  updateFuelsDeleteNonIncluded,
  productionUnitFetchWithoutHistory,
  productionUnitListSearch,
  activateUnitPut,
  deactivateUnitPut,
  productionUnitClassifiers,
} from '../actionHelpers/endpoints';
import {
  PRODUCTION_UNIT_ADD,
  PRODUCTION_UNIT_ENTITY_FETCH,
  PRODUCTION_UNIT_UPDATE,
  PRODUCTION_UNIT_FUEL_UPDATE,
  PRODUCTION_UNIT_CLASSIFIERS,
  PRODUCTION_UNIT_DELETE,
  PRODUCTION_UNIT_UPDATE_FUELS_DELETE_NON_INCLUDED,
  PRODUCTION_UNIT_FETCH,
  PRODUCTION_UNIT_ACTIVATION,
} from '../../constants/productionUnitActionTypes';
import {resetMaster, toggleAddMode} from '../pureActions/addEquipmentActions';
import {fetchProductionUnitMeasurement} from './prodUnitMeasurementPointActionCreators';
import {showProductionUnitModal} from './modalsActionCreators';

export const fetchLegalEntityProductionUnits = createAsyncAction(
  PRODUCTION_UNIT_ENTITY_FETCH,
  legalEntityProductionUnitFetch,
  normalizeHelper([productionUnitSchema])
);

export const fetchLegalEntityProductionUnitsPage = createAsyncPagingAction(
  PRODUCTION_UNIT_ENTITY_FETCH,
  productionUnitListSearch,
  async (data, dispatch) => {
    const promises = [];
    const normalData = data.map(unit => {
      promises.push(dispatch(fetchProductionUnitMeasurement(unit.id)));
      return {...unit, measurementPoint: unit.measurementPointId};
    });

    await Promise.all(promises);

    return normalizeHelper([productionUnitSchema])(normalData);
  }
);

export const fetchAndEditProductionUnitNoHistory = createAsyncAction(
  PRODUCTION_UNIT_FETCH,
  productionUnitFetchWithoutHistory,
  (data, dispatch) => {
    dispatch({
      type: toSuccess(PRODUCTION_UNIT_UPDATE),
      payload: {result: data.id},
    });
    return normalizeHelper(productionUnitSchema)(data);
  }
);

export const addProductionUnit = createAsyncAction(
  PRODUCTION_UNIT_ADD,
  productionUnitAdd,
  (unit, dispatch) => {
    const normalized = normalizeHelper(productionUnitSchema)(unit);
    dispatch({
      type: toSuccess(PRODUCTION_UNIT_UPDATE),
      payload: normalized,
    });
    return normalized;
  }
);

export const startAddProductionUnit = () => dispatch => {
  dispatch(toggleAddMode(true));
  dispatch({
    type: toSuccess(PRODUCTION_UNIT_UPDATE),
    payload: {result: null},
  });
  dispatch(resetMaster());
  dispatch(showProductionUnitModal());
};

export const startEditProductionUnit = id => dispatch => {
  dispatch(toggleAddMode(false));
  dispatch(resetMaster());
  dispatch({
    type: toSuccess(PRODUCTION_UNIT_UPDATE),
    payload: {result: id},
  });
  dispatch(showProductionUnitModal());
};

export const fetchAndEditProductionUnit = createAsyncAction(
  PRODUCTION_UNIT_FETCH,
  productionUnitFetch,
  (data, dispatch) => {
    dispatch({
      type: toSuccess(PRODUCTION_UNIT_UPDATE),
      payload: {result: data.id},
    });
    return normalizeHelper(productionUnitSchema)(data);
  }
);

export const updateProductionUnit = createAsyncAction(
  PRODUCTION_UNIT_UPDATE,
  productionUnitUpdate,
  normalizeHelper(productionUnitSchema)
);

export const deleteFuelField = createAsyncAction(
  PRODUCTION_UNIT_DELETE,
  deleteUnitFuel,
  normalizeHelper(productionUnitSchema)
);

export const updateFuelsWithDelete = createAsyncAction(
  PRODUCTION_UNIT_UPDATE_FUELS_DELETE_NON_INCLUDED,
  updateFuelsDeleteNonIncluded,
  normalizeHelper(productionUnitSchema)
);

export const requestProductionUnitUpdate = createAsyncAction(
  PRODUCTION_UNIT_FUEL_UPDATE,
  productionUnitFuelUpdateRequest,
  normalizeHelper(productionUnitSchema)
);

export const deleteProductionUnit = createAsyncAction(
  PRODUCTION_UNIT_DELETE,
  productionUnitDelete
);

export const activateProductionUnit = createAsyncAction(
  PRODUCTION_UNIT_ACTIVATION,
  activateUnitPut,
  normalizeHelper(productionUnitSchema)
);

export const deactivateProductionUnit = createAsyncAction(
  PRODUCTION_UNIT_ACTIVATION,
  deactivateUnitPut,
  normalizeHelper(productionUnitSchema)
);

export const fetchFeedstockAndProductionPathwayClassifiers = createAsyncAction(
  PRODUCTION_UNIT_CLASSIFIERS,
  productionUnitClassifiers,
);
