import {tCommon} from '../i18n';
import classificatorTypes from '../constants/classificators';
import classifiersStore from './classifiersStore';

export const translateClassificator = type => value => {
  const classifier = classifiersStore.getClassifierByType(type);

  if (classifier) {
    const foundClassifierItem = classifier.find(
      classifierItem => classifiersStore.getCode(classifierItem) === value
    );

    if (foundClassifierItem)
      return classifiersStore.getName(foundClassifierItem);
  }
  return value
    ? tCommon(`classificators.${type}.${value}`, {defaultValue: ''})
    : '';
};

export const translateFuelClassificator = type => (value, record) => {
  if (record) {
    const fuelType = record['fuelType'];
    const classifierTranslation = classifiersStore.getClassifierByKey(`FUEL_TYPE.${fuelType}.${type}.${value}`);

    if (classifierTranslation) {
      return classifierTranslation;
    }
  }
  return value ? tCommon(`classificators.${type}.${value}`, {defaultValue: ''}) : '';
};

export const translateCustomerTypeClassificator = translateClassificator(
  classificatorTypes.consumerType
);
export const translateTransactionTypeClassificator = translateClassificator(
  classificatorTypes.transactionType
);
export const translateGosTransactionTypeClassificator = translateClassificator(
  classificatorTypes.gosTransactionType
);

export const translateGosReportTypeClassificator = translateClassificator(
  classificatorTypes.gosReportType
);
export const translateReportForGosStatusClassificator = translateClassificator(
  classificatorTypes.reportForGosStatus
);

export const translateTransactionForGosStatusClassificator = translateClassificator(
  classificatorTypes.transactionForGosStatus
);
export const translateTransactionStatusClassificator = translateClassificator(
  classificatorTypes.transactionStatus
);
export const translateLegalEntityTypeClassificator = translateClassificator(
  classificatorTypes.legalEntityType
);
export const translateLegalEntityStatusClassificator = translateClassificator(
  classificatorTypes.legalEntityStatus
);
export const translateMandateRoleClassificator = translateClassificator(
  classificatorTypes.mandateRole
);
export const translateFuelTypeClassificator = translateClassificator(
  classificatorTypes.fuelType
);
export const translateFeedstockClassificator = translateFuelClassificator(
  classificatorTypes.feedstock
);
export const translateBiofuelTypeClassificator = translateClassificator(
  classificatorTypes.biofuelType
);
export const translateTsStatusClassificator = translateClassificator(
  classificatorTypes.tsStatus
);
export const translateProductionPathwayClassificator = translateFuelClassificator(
  classificatorTypes.productionPathway
);
export const translateCleaningTechnologyClassificator = translateClassificator(
  classificatorTypes.cleaningTechnologyType
);
export const translateMeteringPointTypeClassificator = translateClassificator(
  classificatorTypes.meteringPointType
);
export const translateMeteringPointDataSourceClassificator = translateClassificator(
  classificatorTypes.meteringPointDataSource
);
export const translateMeteringPointStatusClassificator = translateClassificator(
  classificatorTypes.meteringPointStatus
);
export const translateConsumptionUsageClassificator = translateClassificator(
  classificatorTypes.consumptionUsage
);
export const translateMonthClassificator = translateClassificator(
  classificatorTypes.month
);
export const translateAccountTypeClassificator = translateClassificator(
  classificatorTypes.accountType
);
export const translateCountyClassificator = translateClassificator(
  classificatorTypes.counties
);
export const translateInvoiceStatusClassificator = translateClassificator(
  classificatorTypes.invoiceStatus
);
export const translateWorkersAmountClassificator = translateClassificator(
  classificatorTypes.workersAmount
);
export const translateInvoiceTypeClassificator = translateClassificator(
  classificatorTypes.invoiceType
);
export const translateProductionUnitStatusClassificator = translateClassificator(
  classificatorTypes.productionUnitStatus
);
export const translateConsumptionUsagePeriodClassificator = translateClassificator(
  classificatorTypes.consumptionUsagePeriod
);
export const translateCountryClassificator = translateClassificator(
  classificatorTypes.country
);
export const translateEnergyTypeClassificator = translateClassificator(
  classificatorTypes.energyType
);
export const translateBooleanClassificator = translateClassificator(
  classificatorTypes.boolean
);
export const translatelandUseCategoryClassificator = translateClassificator(
  classificatorTypes.landUseCategory
);
export const translateAgreementTypeClassificator = translateClassificator(
  classificatorTypes.agreementType
);
export const translateTsFuelTypeClassificator = translateClassificator(
  classificatorTypes.tsFuelType
);
export const translateTsFuelGenerationClassificator = translateClassificator(
  classificatorTypes.tsFuelGeneration
);
export const translateTsAssignmentStateClassificator = translateClassificator(
  classificatorTypes.tsAssignmentState
);
export const translateTsAssignmentTypeClassificator = translateClassificator(
  classificatorTypes.tsAssignmentType
);
export const translateTradingMethodClassificator = translateClassificator(
  classificatorTypes.tradingMethod
);
export const translateWithdrawTypeClassificator = translateClassificator(
  classificatorTypes.withdrawType
);
export const translateTradeTypeClassificator = translateClassificator(
  classificatorTypes.tradeType
)

