import {createValidation, validatorRequired} from '../../utils/formValidators';
import economicCertificateClassificator from '../../constants/classificators/economicCertificate';
import economicCertificateNameClassificator
  from '../../constants/classificators/economicCertificateName';

import {technicalInformationFormFields} from '../../constants/formsAddEquipment';

export default createValidation([
  {
    name: technicalInformationFormFields.CLEANING_TECHNOLOGY_TYPE,
    validators: validatorRequired(),
  },
  {
    name: technicalInformationFormFields.NOMINAL_POWER,
    validators: validatorRequired(),
  },
  {
    name: technicalInformationFormFields.ECONOMIC_CERTIFICATE,
    validators: validatorRequired(),
  },
  {
    name: technicalInformationFormFields.SELF_SUFFICIENT_WEIGHT,
    validators: validatorRequired(),
  },
  {
    name: technicalInformationFormFields.CERTIFICATE_END_DATE,
    validators: validatorRequired(),
  },
  {
    name: technicalInformationFormFields.CERTIFICATE_NAME,
    validators: (field, form) =>
      form.get(technicalInformationFormFields.ECONOMIC_CERTIFICATE) ===
      economicCertificateClassificator.VOLUNTARY_SCHEME
        ? validatorRequired()(field)
        : '',
  },
  {
    name: technicalInformationFormFields.CUSTOM_CERTIFICATE_NAME,
    validators: (field, form) =>
      form.get(technicalInformationFormFields.ECONOMIC_CERTIFICATE) !==
      economicCertificateClassificator.VOLUNTARY_SCHEME ||
      form.get(technicalInformationFormFields.CERTIFICATE_NAME) ===
      economicCertificateNameClassificator.other
        ? validatorRequired()(field)
        : '',
  },
]);
