import {fuelsUsedFormFields} from '../../constants/formsAddEquipment';
import classificatorTypes from '../../constants/classificators';
import {fromJS} from 'immutable';
import classifiersStore from '../../utils/classifiersStore';

const getInitialFuels = () => {
  const fuelType = classifiersStore.getClassifierByType(
    classificatorTypes.fuelType
  );
  const initials = fuelType.map(item => ({
    [fuelsUsedFormFields.FUEL_TYPE]: classifiersStore.getCode(item),
    [fuelsUsedFormFields.IS_FUEL_USED]: false,
  }));

  return fromJS(initials);
};

const addInitialFuels = init => {
  const initialFuels = getInitialFuels();

  if (
    init.get(fuelsUsedFormFields.TYPES) &&
    !init.get(fuelsUsedFormFields.TYPES).isEmpty()
  ) {
    return init.set(
      fuelsUsedFormFields.TYPES,
      init
        .get(fuelsUsedFormFields.TYPES)
        .map(f => f.set(fuelsUsedFormFields.IS_FUEL_USED, true))
        .concat(
          initialFuels.filterNot(initFuel =>
            init
              .get(fuelsUsedFormFields.TYPES)
              .map(f => f.get(fuelsUsedFormFields.FUEL_TYPE))
              .includes(initFuel.get(fuelsUsedFormFields.FUEL_TYPE))
          )
        )
    );
  }
  return init.set(fuelsUsedFormFields.TYPES, initialFuels);
};

export default addInitialFuels;
