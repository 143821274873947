import React from 'react';
import {connect} from 'react-redux';
import {Tabs, Spin} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import PropTypes from 'prop-types';
import {getLoadingSelector} from '../../utils/asyncHelpers';
import {
  AddEquipmentGeneral,
  AddEquipmentTechnicalInformation,
  AddEquipmentFuelsUsed,
  AddEquipmentActivityPeriod,
  AddEquipmentDocuments,
} from '../../forms';
import {productionUnitFields} from '../../constants/productionUnit';
import {RequestChangeModal} from '../';
import {notificationRelatedObjectType} from '../../constants/classificators';
import {
  fetchAndEditProductionUnitNoHistory
} from '../../actions/actionCreators/productionUinitActionCreators';
import {showRequestChangeEquipmentModal} from '../../actions/actionCreators/modalsActionCreators';
import {
  getProductionUnit,
  getProductionUnitData,
} from '../../reducers/productionUnitViewReducer';
import RegisterMeasurement from '../RegisterMeasurement/RegisterMeasurement';

const {TabPane} = Tabs;

class AddEquipmentEdit extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    productionUnit: PropTypes.object,
    productionUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchUnit: PropTypes.func.isRequired,
    legalEntityId: PropTypes.number,
    showRequestChange: PropTypes.func.isRequired,
    queryParams: PropTypes.object
  };

  componentDidMount = () => {
    const {productionUnitId, fetchUnit} = this.props;

    if (productionUnitId) fetchUnit(productionUnitId);
  };

  render() {
    const {
      t,
      isEditMode,
      isAdmin,
      productionUnit,
      updateState,
      isLoading,
      legalEntityId: passedLegalEntityId,
      showRequestChange,
      queryParams
    } = this.props;

    const legalEntityId = !productionUnit.isEmpty()
      ? productionUnit.get(productionUnitFields.LEGAL_ENTITY_ID)
      : passedLegalEntityId;

    const key = queryParams && queryParams.tab ? queryParams.tab : '1';

    const meteringPointId = productionUnit.get(productionUnitFields.METERING_POINT_ID);

    return (
      <Spin spinning={isLoading}>
        <h1>{isEditMode ? t('titleEdit') : t('title')}</h1>
        <Tabs type="card" defaultActiveKey={key}>
          <TabPane tab={t('title_general')} key="1">
            <AddEquipmentGeneral
              isEditMode={isEditMode}
              t={t}
              legalEntityId={legalEntityId}
              productionUnit={productionUnit}
              isAdmin={isAdmin}
              inEditForNotAdmin={isEditMode && !isAdmin}
              isProductionUnitAdd={false}
              selectProductionUnitOwner={false}
              showRequestChangeModal={showRequestChange}
            />
          </TabPane>
          <TabPane tab={t('title_technical_info')} key="2">
            <AddEquipmentTechnicalInformation
              isEditMode={isEditMode}
              t={t}
              legalEntityId={legalEntityId}
              productionUnit={productionUnit}
              isAdmin={isAdmin}
              inEditForNotAdmin={isEditMode && !isAdmin}
              showRequestChangeModal={showRequestChange}
            />
          </TabPane>
          <TabPane tab={t('title_fuels_proportion')} key="3">
            {meteringPointId && (<AddEquipmentFuelsUsed
              isEditMode={isEditMode}
              t={t}
              legalEntityId={legalEntityId}
              productionUnit={productionUnit}
              isAdmin={isAdmin}
              isLoading={isLoading}
              updateState={updateState}
              inEditForNotAdmin={isEditMode && !isAdmin}
              showRequestChangeModal={showRequestChange}
            />)}
          </TabPane>
          <TabPane tab={t('title_activity_period')} key="4">
            <AddEquipmentActivityPeriod
              isEditMode={isEditMode}
              t={t}
              legalEntityId={legalEntityId}
              productionUnit={productionUnit}
              isAdmin={isAdmin}
              inEditForNotAdmin={isEditMode && !isAdmin}
              showRequestChangeModal={showRequestChange}
            />
          </TabPane>
          <TabPane tab={t('title_measurement')} key="5">
            <RegisterMeasurement
              isEditMode={isEditMode}
              t={t}
              legalEntityId={legalEntityId}
              productionUnit={productionUnit}
              isAdmin={isAdmin}
              inEditForNotAdmin={isEditMode && !isAdmin}
              showRequestChangeModal={showRequestChange}
            />
          </TabPane>
          <TabPane tab={t('title_documents')} key="6">
            <AddEquipmentDocuments
              isEditMode={isEditMode}
              t={t}
              legalEntityId={legalEntityId}
              productionUnit={productionUnit}
              isAdmin={isAdmin}
              inEditForNotAdmin={isEditMode && !isAdmin}
              showRequestChangeModal={showRequestChange}
            />
          </TabPane>
        </Tabs>
        <RequestChangeModal
          relatedObjectId={productionUnit.get(productionUnitFields.ID)}
          relatedObjectType={notificationRelatedObjectType.PRODUCTION_UNIT}
        />
      </Spin>
    );
  }
}

const getIsLoading = getLoadingSelector(getProductionUnit);

const mapStateToProps = state => ({
  productionUnit: getProductionUnitData(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = {
  fetchUnit: fetchAndEditProductionUnitNoHistory,
  showRequestChange: showRequestChangeEquipmentModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.addEquipment)(AddEquipmentEdit)
);
