import React, {Component} from 'react';
import {Button} from 'antd';
import PropTypes from 'prop-types';
import {goBack} from '../../utils/gotoLink';
import './RegisterActions.css';

const ButtonNext = ({message, goNext, disabled}) => (
  <Button
    className="component-register-actions__button-next"
    size="large"
    type="primary"
    htmlType="submit"
    onClick={goNext || null}
    disabled={disabled}
  >
    {message}
  </Button>
);

const ButtonPrevious = ({goPrevious, t}) => (
  <Button
    className="component-register-actions__button-previous"
    size="large"
    onClick={goPrevious}
  >
    {t('button_previous')}
  </Button>
);

class RegisterActions extends Component {
  static propTypes = {
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    goNext: PropTypes.func,
    showRequestChangeModal: PropTypes.func,
    children: PropTypes.node,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    showSaveButton: PropTypes.bool.isRequired,
    saveButtonDisabled: PropTypes.bool,
    hasExit: PropTypes.bool,
  };

  static defaultProps = {
    hasPrevious: false,
    hasDone: false,
    hasExit: false,
    showRequestChangeButton: true,
  };

  getNextTitle = () => {
    const {t, hasDone, isEditMode} = this.props;

    if (isEditMode) {
      return t('button_save');
    }

    return hasDone ? t('button_done') : t('button_next');
  };

  render() {
    const {
      hasPrevious,
      goPrevious,
      t,
      goNext,
      isEditMode,
      isAdmin,
      isOpen,
      children,
      showRequestChangeModal,
      showSaveButton,
      saveButtonDisabled,
      hasExit,
      showRequestChangeButton
    } = this.props;

    return (
      <div className="component-register-actions">
        {showRequestChangeButton &&
          !isAdmin &&
          !isOpen &&
          isEditMode && (
            <Button size="large" onClick={showRequestChangeModal}>
              {t('requestChangeBtn')}
            </Button>
          )}
        {children}
        {hasPrevious && <ButtonPrevious goPrevious={goPrevious} t={t}/>}
        {hasExit &&
          !hasPrevious && <ButtonPrevious goPrevious={goBack} t={t}/>}
        {showSaveButton && (
          <ButtonNext t={t} goNext={goNext} message={this.getNextTitle()}
                      disabled={saveButtonDisabled}/>
        )}
      </div>
    );
  }
}

export default RegisterActions;
