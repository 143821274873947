import {history} from '../store';

const gotoLink = link => history.push(link);
const replaceLink = link => history.replace(link);
export const goBack = () => history.goBack();

export const links = {
  root: '/',
  oauth: `${window.location.origin}/oauth2/authorization/taas`,
  oauthLogout: '/oauth2/postlogout',
  register: '/register',
  equip: '/equip',
  productionUnitSearch: '/productionUnit/search',
  consumptionPoint: '/consumptionPoint',
  productionUnitAdd: '/productionUnit/add',
  notifications: '/notifications',
  cookies: '/cookies',
  search: '/search',
  meteringPointList: '/meteringPointList',
  electricMeteringPointList: '/electricMeteringPointList',
  classifiers: '/classifiers',
  invoices: '/invoice/search',
  transactionExportCsv: '/transaction/csvReport',
  periodicReportExportCsv: '/account/go/download/periodicReport',
  goSearchExportCsv: '/account/go/download/search',
  invoiceExportCsv: '/invoice/download/search',
  accountGOSOverviewCsv: '/tradingAccount/overview/csv',
  accountGOSTransactionCsv: '/accountGOS/download/transaction',
  accountGOSReportCsv: '/accountGOS/download/report',
  consumerReportExportCsv: '/consumer/go/search/download',
  legalEntityExportCsv: '/legalEntity/download/search',
  transactionGOSCsv: '/transactionForGos/download/csv',
  tradingAccountCsv: '/tradingAccount/download/csv',
  productionUnitFuelsUsedHistoryCsv: '/productionUnit/csvHistory',
  reportGrid: '/report/grid',
  transactionsGo: '/transaction/search/go',
  transactionsGos: '/transaction/search/gos',
  reportGo: '/report/go',
  reportPeriod: '/report/period',
  reportPeriodGos: '/report/periodGos',
  electricConsumptionReport: '/report/electricConsumption',
  reportGoXls: '/api/v1/account/go/xlsReport',
  reportGoXlsDate: '/account/go/xlsReportDate',
  reportGosXls: '/api/v1/report/renewableEnergy/xlsReport',
  electricConsumptionReportXls: '/api/v1/electricReport/consumption/xls',
  consumerReports: '/consumer/reports',
  error403: '/error/403',
  consumerError403: '/error/consumer/403',
  apiLogout: '/api/v1/logout',
  eleringEe: 'https://e.elering.ee/#/',
  productionUnitView: id => `/productionUnit/${id}`,
  accountGOSSearch: registerCode => `/accountGOS/search/${registerCode}`,
  tsTransactionView: (transactionId, type) => `/tradingAccountTransaction/view/${type}/${transactionId}`,

  loadingUnitList: '/loadingUnit/list',
  loadingUnitAdd: '/loadingUnit/add',
  loadingUnitSearch: '/loadingUnit/search',
  loadingUnitView: id => `/loadingUnit/${id}`,

  vdsMessagesExportCsv: '/vdsMessages/download/csv',

  tsSellingAgreementsCsv: '/tsAgreement/download/csv/selling',
  tsPurchasingAgreementsCsv: '/tsAgreement/download/csv/purchasing',
  tsAllAgreementsCsv: '/tsAgreement/download/csv/admin',

  tsAuctionHistoryCreatedCsv: '/tsAuction/download/csv/created',
  tsAuctionHistoryWonCsv: '/tsAuction/download/csv/won',
  tsAuctionHistoryEndedCsv: '/tsAuction/download/csv/ended',
  tsAuctionSearchCsv: '/tsAuction/download/csv/search',

  tsAuctionHistory: registerCode => `/accountGOS/auctions/${registerCode}/history`,
  tsAuctionDetails: (registerCode, auctionId) => `/accountGOS/auctions/${registerCode}/view/${auctionId}`,

  tradingPlatformAgreements: '/tradingPlatform/agreements',
  tradingPlatformAuctions: '/tradingPlatform/auctions'
};

export const goToAccountOverview = id => gotoLink(`/account/${id}`);
export const goToAccountReport = id =>
  gotoLink(id ? `/accountReport/${id}` : '/accountReport');
export const goToNotifications = () => gotoLink(links.notifications);
export const goToLogin = () => gotoLink('/login');
export const goToDashboard = () => gotoLink('/notifications');
export const goToMeteringPointList = () => gotoLink(links.meteringPointList);
export const goToElectricMeteringPointList = () => gotoLink(links.electricMeteringPointList);
export const goToLegalEntity = id => gotoLink(`/legalEntity/${id}`);
export const goToRegisterByAdmin = () => gotoLink('/register');
export const replaceToRegister = id => replaceLink(`/register/${id}`);
export const goToRegister = id => gotoLink(`/register/${id}`);
export const goToSearch = () => gotoLink(links.search);
export const goToConsumptionPoint = () => gotoLink(links.consumptionPoint);
export const goToProductionUnitSearch = () =>
  gotoLink(links.productionUnitSearch);
export const goToProductionUnitAdd = () => gotoLink(links.productionUnitAdd);
export const goToProductionUnitView = id =>
  gotoLink(links.productionUnitView(id));
export const goToMeteringPointView = (id, tab) =>
  gotoLink(`/meteringPoint/${id}${tab ? `/${tab}` : ''}`);
export const goToElectricMeteringPointView = (id, tab) =>
  gotoLink(`/electricMeteringPoint/${id}${tab ? `/${tab}` : ''}`);
export const goToUserTransactions = id => gotoLink(`/transaction/search/${id}`);
export const goToTransactionsGo = () => gotoLink('/transaction/search/go');
export const goToTransactionsGos = () => gotoLink('/transaction/search/gos');
export const goToTransactionView = id => gotoLink(`/transaction/view/${id}`);
export const goToClassifiers = () => gotoLink(links.classifiers);
export const goToClassifierView = id => gotoLink(`/classifiers/${id}`);
export const goToClassifierSubView = (id, subId) => gotoLink(`/classifiers/${id}/${subId}`);
export const goToClassifierSubValuesView = (classifier, classifierValue, itemId) => gotoLink(`/classifiers/${classifier}/${classifierValue}/${itemId}`);
export const goToInvoiceView = id => gotoLink(`/invoice/view/${id}`);
export const goToInvoices = () => gotoLink('/invoice/search');
export const goToReportGrid = () => gotoLink(links.reportGrid);
export const goToReportGo = () => gotoLink(links.reportGo);
export const goToReportPeriod = () => gotoLink(links.reportPeriod);
export const goToReportPeriodGos = () => gotoLink(links.reportPeriodGos);
export const goToElectricConsumptionReport = () => gotoLink(links.electricConsumptionReport);
export const goToTransferring = accountId =>
  gotoLink(`/transferring/${accountId}`);
export const goToCancelingCertificates = accountId =>
  gotoLink(`/cancelingCertificates/${accountId}`);

export const currentPathSelector = state =>
  state.get('routing').location.pathname;

export const getLinkInvoiceExport = id => `/invoice/${id}/pdf`;
export const goToConsumerReports = () => gotoLink(links.consumerReports);
export const goToError403 = () => gotoLink(links.error403);
export const goToCounsumerError403 = () => gotoLink(links.consumerError403);

export const goToAccountGOSSearch = registerCode =>
  gotoLink(`/accountGOS/search/${registerCode}`);
export const goToAccountGOSTransactions = registerCode => gotoLink(`/accountGOS/transactions/${registerCode}`);
export const goToAccountGOSReport = registerCode => gotoLink(`/accountGOS/report/${registerCode}`);
export const goToAccountGOSPreTradingAgreements = registerCode => gotoLink(`/accountGOS/pre-trading/${registerCode}`);
export const goToAccountGOSAuctions = registerCode => gotoLink(`/accountGOS/auctions/${registerCode}`);
export const goToAccountGOSTsAssignments = registerCode => gotoLink(`/accountGOS/ts-assignments/${registerCode}`);

export const goToAccountGOSPreTradingAgreementDetails = (registerCode, agreementId) => gotoLink(`/accountGOS/pre-trading/${registerCode}/view/${agreementId}`);
export const goToAccountGOSReservations = (registerCode, agreementId) => gotoLink(`/accountGOS/pre-trading/${registerCode}/reserve/${agreementId}`)

export const goToTSTransactionView = (type, transactionId) =>
  gotoLink(`/tradingAccountTransaction/view/${type}/${transactionId}`);

export const goToTradingAccountTransfer = type => gotoLink(`/tradingAccount/transfer/${type}`);
export const goToTradingAccountLock = type => gotoLink(`/tradingAccount/lock/${type}`);
export const goToAddAuction = type => gotoLink(`/tradingAccount/addAuction/${type}`);
export const goToTsAgreementRegistration = () => gotoLink('/tradingAccount/tsAgreement/Registration');
export const goToVDSReportRequest = () => gotoLink('/admin/vdsTools/reportRequest');
export const goToVDSTransferRequest = () => gotoLink('/admin/vdsTools/transferRequest');
export const goToVDSTransferCancelRequest = () => gotoLink('/admin/vdsTools/transferCancelRequest');
export const goToVDSOfferRequest = () => gotoLink('/admin/vdsTools/offerRequest');
export const goToVDSOfferCancelRequest = () => gotoLink('/admin/vdsTools/offerCancelRequest');

export const goToVDSMessages = () => gotoLink('/vdsMessages');

export const goToCookies = () => gotoLink(links.cookies);

export const goToLoadingUnitList = () => gotoLink(links.loadingUnitList);
export const goToLoadingUnitAdd = () => gotoLink(links.loadingUnitAdd);
export const goToLoadingUnitSearch = () => gotoLink(links.loadingUnitSearch);
export const goToLoadingUnitView = id => gotoLink(links.loadingUnitView(id));
export const goToTsAuctionHistory = registerCode => gotoLink(`/accountGOS/auctions/${registerCode}/history`);
export const goToAccountGOSAuctionDetails = (registerCode, auctionId) => gotoLink(`/accountGOS/auctions/${registerCode}/view/${auctionId}`);

export const goToTradingPlatformAgreements = () => gotoLink(links.tradingPlatformAgreements);
export const goToTradingPlatformAuctions = () => gotoLink(links.tradingPlatformAuctions);

export default gotoLink;
